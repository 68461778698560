<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:38
-->
<template>
	<div class="volunteerData">
		<div class="volunteerData-content">
			<div class="volunteerData-search">
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">性别</span>
						<el-select class="common-row-ipt" v-model="searchForm.gender" placeholder="请选择">
							<el-option v-for="item in genderList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="common-row-item">
						<span class="common-row-label">学院</span>
						<el-select class="common-row-ipt" v-model="searchForm.collegeId" placeholder="请选择">
							<el-option v-for="item in derictionList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="common-row-item">
						<span class="common-row-label">学号</span>
						<el-input class="common-row-ipt" placeholder="请输入学号" v-model="searchForm.studentId" />
					</div>
				</div>
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">学生类型</span>
						<el-select class="common-row-ipt" v-model="searchForm.studentType" placeholder="请选择">
							<el-option v-for="item in studentTypeList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="common-row-item">
						<span class="common-row-label">活动名称</span>
						<el-input class="common-row-ipt" placeholder="请输入活动名称" v-model="searchForm.activityName" />
					</div>
					<div class="common-row-item">
						<span class="common-row-label">活动时间</span>
						<el-date-picker class="common-row-ipt" :clearable="false" value-format="yyyy-MM-dd"
							@change="dateChange" v-model="searchForm.time" type="daterange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels>
						</el-date-picker>
					</div>
					<!-- <div class="common-row-item">
						<el-button @click="search" type="primary">查询</el-button>
						<el-button @click="reset">重置</el-button>
					</div>
					<div class="common-row-item">
						<div class="common-row-out">
							<el-button v-preventReClick @click="downLoadFile">导出数据</el-button>
						</div>
					</div> -->
				</div>
				<div class="common-row">
					<div class="common-row-item">
						<el-button @click="search" type="primary">查询</el-button>
						<el-button @click="reset">重置</el-button>
					</div>
					<div class="common-row-item">
						<div class="common-row-out">
							<el-button v-preventReClick @click="downLoadFile">导出数据</el-button>
						</div>
					</div>
				</div>
			</div>
			<div class="common-table">
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">学生总工时（小时）</span>
						<el-input readonly="readonly" v-model="volunteerTotal.workingHoursTotal" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">志愿者证注册（人）</span>
						<el-input readonly="readonly" v-model="volunteerTotal.volunteerRegisterNum" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">5星志愿者（人）</span>
						<el-input readonly="readonly" v-model="volunteerTotal.fiveStarNum" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">4星志愿者（人）</span>
						<el-input readonly="readonly" v-model="volunteerTotal.fourStarNum" placeholder="请输入"
							class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">3星志愿者（人）</span>
						<el-input readonly="readonly" v-model="volunteerTotal.threeStarNum" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">2星志愿者（人）</span>
						<el-input readonly="readonly" v-model="volunteerTotal.twoStarNum" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">1星志愿者（人）</span>
						<el-input readonly="readonly" v-model="volunteerTotal.oneStarNum" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">无星级志愿者（人）</span>
						<el-input readonly="readonly" v-model="volunteerTotal.noStarNum" placeholder="请输入"
							class="common-table-val" />
					</div>
				</div>
			</div>
			<div class="volunteerData-table">
				<el-table :data="tableData" border>
					<el-table-column width="150px" prop="name" label="学生姓名">
					</el-table-column>
					<el-table-column width="100px" prop="gender" label="性别">
						<template slot-scope="scope">
							<span>{{scope.row.gender == true?"男":scope.row.gender == false?"女":"-"}}</span>
						</template>
					</el-table-column>
					<el-table-column width="180px" prop="studentId" label="学号">
					</el-table-column>
					<el-table-column prop="collegeName" label="学院">
					</el-table-column>
					<el-table-column width="100px" prop="educationLevel" label="学生类型">
						<template slot-scope="scope">
							<span>{{scope.row.educationLevel == 1?"本科生":"研究生"}}</span>
						</template>
					</el-table-column>
					<el-table-column width="150px" prop="workingHours" label="总工时">
					</el-table-column>
					<el-table-column width="100px" prop="star" label="志愿者星级">
					</el-table-column>
					<el-table-column width="150px" prop="starYea" label="认定年份">
					</el-table-column>
					<!-- <el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('verify', scope)">{{currentTab == 0 ?'审核':'详情'}}</span>
							</div>
						</template>
					</el-table-column> -->
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		totalVolunteer,
		allVolunteer,
		downLoadVolunteer,
	} from "@/api/totaldata";
	import {
		recruitFacultyList,
	} from "@/api/recruit";
	export default {
		data() {
			return {
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				searchForm: {},
				volunteerTotal: {},
				genderList: [{
					label: "男",
					value: "true"
				}, {
					label: "女",
					value: "false"
				}, ],
				derictionList: [],
				studentTypeList: [{
					label: "本科生",
					value: 1
				}, {
					label: "研究生",
					value: 2
				}, ],
			};
		},
		mounted() {
			this.getRecruitFacultyList()
			this.getVolunteerTotal("1")
		},
		methods: {
			actionFn(type, scope) {
				switch (type) {
					case "verify":
						this.$router.push({
							path: "/voteSet/detail"
						})
						break;
				}
			},
			getRecruitFacultyList() {
				let params = {
					pageIndex: 1,
					pageSize: 999,
				};
				recruitFacultyList(params).then((res) => {
					this.derictionList = res.data
				})
			},
			dateChange(value) {
				if (value) {
					this.searchForm.activityStartDay = value[0];
					this.searchForm.activityEndDay = value[1];
				} else {
					this.searchForm.activityStartDay = "";
					this.searchForm.activityEndDay = "";
				}
			},
			getVolunteerTotal(type) {
				let params = {}
				if (this.searchForm.collegeId) {
					this.$set(params, "collegeId", this.searchForm.collegeId)
				}
				if (this.searchForm.gender) {
					this.$set(params, "gender", this.searchForm.gender)
				}
				if (this.searchForm.studentId) {
					this.$set(params, "studentId", this.searchForm.studentId)
				}
				if (this.searchForm.studentType) {
					this.$set(params, "studentType", this.searchForm.studentType)
				}
				if (this.searchForm.time) {
					this.$set(params, "activityStartDay", this.searchForm.activityStartDay)
					this.$set(params, "activityEndDay", this.searchForm.activityEndDay)
				}
				if (this.searchForm.activityName) {
					this.$set(params, "activityName", this.searchForm.activityName)
				}
				if (type == '1') {
					totalVolunteer(params).then((res) => {
						if (res.code == 0) {
							this.volunteerTotal = res.data
						}
					})
				} else if (type == 2) {
					downLoadVolunteer(params).then((res) => {
						const blob = new Blob([res], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
						// const blob = new Blob([res], {type: "application/vnd.ms-excel;charset=utf-8"})
						if ('download' in document.createElement('a')) {
							// 非IE下载
							const elink = document.createElement('a')
							elink.download = "志愿者数据表.xlsx"
							elink.style.display = 'none'
							elink.href = URL.createObjectURL(blob)
							document.body.appendChild(elink)
							elink.click()
							URL.revokeObjectURL(elink.href) // 释放URL 对象
							document.body.removeChild(elink)
						} else {
							// IE10+下载
							navigator.msSaveBlob(blob, "志愿者数据表.xlsx")
						}
					})
				}
				allVolunteer({
					...params,
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
				}).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			downLoadFile() {
				this.getVolunteerTotal("2")
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.volunteerTotal = {};
				this.getVolunteerTotal("1");
			},
			search() {
				this.pageIndex = 1;
				this.tableData = [];
				this.volunteerTotal = {};
				this.getVolunteerTotal("1")
			},
			reset() {
				this.searchForm = {}
				this.pageIndex = 1;
				this.tableData = [];
				this.volunteerTotal = {};
				this.getVolunteerTotal("1")
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.el-button {
		height: 36px;
	}

	/deep/.common-row-out .el-button--default {
		border: 1px solid #DCDFE6 !important;
		color: #606266 !important;
	}


	.flex-between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.flex-item {
		display: flex;
		align-items: center;
	}

	.common-table-label {
		width: 162px !important;
	}

	.volunteerData {
		.volunteerData-content {
			padding: 0 20px 20px 20px;

			.volunteerData-search {
				.common-row {
					margin: 16px 0;

					.common-row-item {
						margin-right: 20px;

						.common-row-ipt {
							flex: 1;
							height: 36px;
							line-height: 36px;
							border: 1px solid #EDF1F7;
							border-left: 0;
						}
					}

					.common-row-out {
						margin-left: auto;
					}
				}
			}

			.volunteerData-table {
				margin-top: 20px;
			}
		}
	}
</style>
